import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { User } from 'src/models/user.model';
import { Advert } from 'src/models/advert.model';
import { Lookup } from 'src/models/lookup.model';
import { AppDataProvider } from 'src/app/app.data.provider';
@Component({
  selector: 'app-filter-ads',
  templateUrl: './filter-ads.page.html',
  styleUrls: ['./filter-ads.page.scss'],
})
export class FilterAdsPage implements OnInit {
  data: Array<Advert> = null;
  originalData: Array<Advert> = null;
  cities: Array<string>;
  provinces: Array<string>;
  selectedProvince: string;
  selectedCity: string;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
    private appDataProvider: AppDataProvider,
  ) {
    this.data = new Array<Advert>();
    this.provinces = new Array<string>();
    this.cities = new Array<string>();
    this.selectedProvince = undefined;
    this.selectedCity = undefined;
  }

  ngOnInit() {
    if (
      this.appDataProvider.user === null ||
      this.appDataProvider.user === undefined ||
      this.appDataProvider.lookupData === null ||
      this.appDataProvider.lookupData === undefined ||
      this.appDataProvider.lookupData.length === 0
    ) {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
      return;
    }

    this.data = this.params.get('data');
    this.originalData = this.params.get('originalData');

    this.originalData.map(record => {

      if (this.cities.indexOf(record.user.city) === -1)
        this.cities.push(record.user.city);

      if (this.provinces.indexOf(record.user.province_desc) === -1)
        this.provinces.push(record.user.province_desc);
    });

    this.cities.sort();
    this.provinces.sort();

    if (this.params.get('filtedApplied')) {
      this.selectedProvince = this.params.get('selectedProvince');
      this.selectedCity = this.params.get('selectedCity');
    }
  }

  async back() {
    await this.modalController.dismiss({
      selectedProvince: undefined,
      selectedCity: undefined,
      filtedApplied: false,
      data: this.originalData,
    });
  }

  async reset() {
    await this.modalController.dismiss({
      selectedProvince: undefined,
      selectedCity: undefined,
      filtedApplied: false,
      data: this.originalData,
    });
  }

  async apply() {
    let newData = this.originalData;

    if (this.selectedCity !== undefined) {
      newData = newData.filter(item => {
        return item.user.city === this.selectedCity;
      });
    }

    if (this.selectedProvince !== undefined) {
      newData = newData.filter(item => {
        return item.user.province_desc === this.selectedProvince;
      });
    }

    await this.modalController.dismiss({
      selectedProvince: this.selectedProvince,
      selectedCity: this.selectedCity,
      filtedApplied: true,
      data: newData,
    });
  }
}
