import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { User } from 'src/models/user.model';

@Component({
  selector: 'app-specify-hunters',
  templateUrl: './specify-hunters.page.html',
  styleUrls: ['./specify-hunters.page.scss'],
})
export class SpecifyHuntersPage implements OnInit {
  selectedHunters: Array<User>;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {
    this.selectedHunters = new Array<User>();
  }

  ngOnInit() {
    this.selectedHunters = this.params.get('selectedHunters');
  }

  addContact() {
    // this.selectedHunters.push({
    //   firstname: 'Joe ' + this.selectedHunters.length +1,
    //   lastname: 'Soap',
    //   mobile: '0836564309',
    // });
    var that = this;

    const contacts = navigator['contacts'];
    contacts.pickContact(
      function (contact) {
        that.selectedHunters.push({
          firstname: contact.name.givenName,
          lastname: contact.name.familyName,
          mobile: contact.phoneNumbers[0].value,
        });
        // alert(
        //   'The following contact has been selected:' +
        //     JSON.stringify(contact.name.givenName),
        // );
      },
      function (err) {
        if (JSON.stringify(err).indexOf('20') !== -1) {
          alert(
            'Contacts permission denied. Please enable contacts access for this app from your phone settings.',
          );
        }
      },
    );
  }

  async back() {
    await this.modalController.dismiss(this.selectedHunters);
  }

  delete(hunter) {
    let newHunters = this.selectedHunters.filter(item => {
      return hunter != item;
    });
    this.selectedHunters = newHunters;
  }

  specifyHunter(hunter) {
    const that = this;

    const contacts = navigator['contacts'];
    contacts.pickContact(
      function (contact) {
        that.selectedHunters.shift();
        that.selectedHunters.push({
          firstname: contact.name.givenName,
          lastname: contact.name.familyName,
          mobile: contact.phoneNumbers[0].value,
        });
      },
      function (err) {
        if (JSON.stringify(err).indexOf('20') !== -1) {
          alert(
            'Contacts permission denied. Please enable contacts access for this app from your phone settings.',
          );
        }
      },
    );
  }
}
