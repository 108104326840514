import { User } from '../models/user.model';

export class Experience {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  userid?: number;
  animalid?: number;
  photosjson?: string;
  description?: string;
  farm?: string;
  farmcontact?: string;
  huntfromdate?: Date;
  hunttodate?: Date;
  hornsize?: number;
  weight?: number;  

  // Runtime properties (not from DB)
  user?: User;
  defaultimage: string;
  animalid_desc?: string;
  likedexperience?: boolean;
  likecount?: number;
}