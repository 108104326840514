import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Advert } from 'src/models/advert.model';
@Component({
  selector: 'app-sort-ads',
  templateUrl: './sort-ads.page.html',
  styleUrls: ['./sort-ads.page.scss'],
})
export class SortAdsPage implements OnInit {
  data: Array<Advert> = null;
  originalData: Array<Advert> = null;
  sortField: string;
  sortDirection: string;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) { 
    this.data = new Array<Advert>();
    this.sortDirection = 'ascending';
    this.sortField = 'availability';
  }

  ngOnInit() {
    this.data = this.params.get('data');
    this.originalData = this.params.get('originalData');
  }

  async back() {
    await this.modalController.dismiss({});
  }

  async reset() {
    await this.modalController.dismiss(this.originalData);
  }

  async apply() {
    let newData = this.data.sort((a, b) => {
        if (this.sortField === 'province') {
        let sortResult = a.user.province_desc.localeCompare(
          b.user.province_desc,
        );
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'city') {
        let sortResult = a.user.city.localeCompare(b.user.city);
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      }

      return 0;
    });

    await this.modalController.dismiss(newData);
  }
}
