import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { User } from 'src/models/user.model';
import { Auction } from 'src/models/auction.model';
import { AppDataProvider } from 'src/app/app.data.provider';
import { Lookup } from 'src/models/lookup.model';

@Component({
  selector: 'app-filter-auctions',
  templateUrl: './filter-auctions.page.html',
  styleUrls: ['./filter-auctions.page.scss'],
})
export class FilterAuctionsPage implements OnInit {
  data: Array<Auction> = null;
  originalData: Array<Auction> = null;

  animals: Array<Lookup>;
  selectedAnimal?: Lookup;
  cities: Array<string>;
  provinces: Array<string>;
  selectedProvince: string;
  selectedCity: string;
  selectedMaleCount: number;
  selectedFemaleCount: number;
  selectedTropheeCount: number;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
    private appDataProvider: AppDataProvider,
  ) {
    this.data = new Array<Auction>();
    this.selectedMaleCount = undefined;
    this.selectedFemaleCount = undefined;
    this.selectedTropheeCount = undefined;
    this.provinces = new Array<string>();
    this.cities = new Array<string>();
    this.selectedProvince = undefined;
    this.selectedCity = undefined;
    this.selectedAnimal = undefined;
  }

  ngOnInit() {
    if (
      this.appDataProvider.user === null ||
      this.appDataProvider.user === undefined ||
      this.appDataProvider.lookupData === null ||
      this.appDataProvider.lookupData === undefined ||
      this.appDataProvider.lookupData.length === 0
    ) {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
      return;
    }
    this.data = this.params.get('data');
    this.originalData = this.params.get('originalData');

    this.animals = this.appDataProvider.lookupData.filter(
      item => item.lookuptype == 'animalid',
    );

    this.originalData.map(record => {
      if (this.cities.indexOf(record.user.city) === -1)
        this.cities.push(record.user.city);

      if (this.provinces.indexOf(record.user.province_desc) === -1)
        this.provinces.push(record.user.province_desc);
    });

    this.cities.sort();
    this.provinces.sort();

    if (this.params.get('filtedApplied')) {
      this.selectedMaleCount = this.params.get('selectedMaleCount');
      this.selectedFemaleCount = this.params.get('selectedFemalealeCount');
      this.selectedTropheeCount = this.params.get('selectedTropheeCount');
      this.selectedCity = this.params.get('selectedCity');
      this.selectedProvince = this.params.get('selectedProvince');
      this.selectedAnimal = this.params.get('selectedAnimal');
    }
  }

  async back() {
    await this.modalController.dismiss({
      selectedMaleCount: undefined,
      selectedFemaleCount: undefined,
      selectedTropheeCount: undefined,
      selectedCity: undefined,
      selectedProvince: undefined,
      selectedAnimal: undefined,
      filtedApplied: false,
      data: this.originalData,
    });
  }

  async reset() {
    await this.modalController.dismiss({
      selectedMaleCount: undefined,
      selectedFemaleCount: undefined,
      selectedTropheeCount: undefined,
      selectedCity: undefined,
      selectedProvince: undefined,
      selectedAnimal: undefined,
      filtedApplied: false,
      data: this.originalData,
    });
  }

  async apply() {
    let newData = this.data;
    if (this.selectedCity !== undefined) {
      newData = newData.filter(item => {
        return item.user.city === this.selectedCity;
      });
    }

    else if (this.selectedProvince !== undefined) {
      newData = newData.filter(item => {
        return item.user.province_desc === this.selectedProvince;
      });
    }

    if (this.selectedAnimal !== undefined) {
      newData = newData.filter(item => {
        return item.animalid == this.selectedAnimal;
      });
    }

    if (this.selectedMaleCount !== undefined) {
      newData = newData.filter(item => {
        return item.malecount <= this.selectedMaleCount;
      });
    }

    else if (this.selectedFemaleCount !== undefined) {
      newData = newData.filter(item => {
        return item.femalecount <= this.selectedFemaleCount;

      });
    }

    else if (this.selectedTropheeCount !== undefined) {
      newData = newData.filter(item => {
        return item.tropheescount <= this.selectedTropheeCount;
      });
    }

    await this.modalController.dismiss({
      selectedMaleCount: this.selectedMaleCount,
      selectedFemaleCount: this.selectedFemaleCount,
      selectedTropheeCount: this.selectedTropheeCount,
      selectedCity: this.selectedCity,
      selectedProvince: this.selectedProvince,
      selectedAnimal: this.selectedAnimal,
      filtedApplied: true,
      data: newData,
    });
  }
}
