import { User } from '../models/user.model';

export class Booking {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  userid: number;
  farmid: number;
  fromdate: string;
  todate: string;
  nights: number;
  huntersjson: string;
  hunters: number;
  observers: number;
  speciesjson: string;
  bookingstatusid: number;
  cateringoptionid?: number;
  bow?: boolean;
  gun?: boolean;
  notes: string;

  // Runtime properties (not from DB)
  user?: User;
  farm?: User;
}
