export class Search {
  fromdate?: Date;
  todate?: Date;
  nights?: number;
  species?: string;
  hunters?: number;
  observers?: number;
  provinceid?: number;
  bow?: boolean;
  gun?: boolean;
  catering?: number;
}
