import { Accomodation } from '../models/accommodation.model';
import { FarmGame } from '../models/farmgame.model';
import { Advert } from './advert.model';

export class User {
  id?: number;
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
  userroleid?: number;
  firstname?: string;
  lastname?: string;
  email?: string;
  password?: string;
  mobile?: string;
  deviceid?: string;
  address?: string;
  location?: object;
  licensedgun?: boolean;
  bow?: boolean;
  biltonghunter?: boolean;
  tropheehunter?: boolean;
  profileimage?: string;
  associationid?: number;
  associationmemno?: string;  
  farmname?: string;
  registered?: boolean;
  regno?: string;
  terain_plains?: boolean;
  terain_bush?: boolean;
  terain_mountains?: boolean;
  hunt_gun?: boolean;
  hunt_bow?: boolean;
  hunt_bowhide?: boolean;  
  hunt_bakkie?: boolean;
  hunt_bakkie_fee?: number;
  hunt_day?: boolean;
  hunt_day_fee?: number;
  hunt_ph?: boolean;
  hunt_ph_fee?: number;
  provinceid?: number;
  information?: string;
  availabilityjson?: string;
  city?: string;
  catering?: number;
  website?: string;

  gpslat?: number;
  gpslng?: number;
  //Require an image in Details under My Game Farm Profile
  //Image is for search results
  photosjson?: string;
  defaultimage?: string;
  farmbanner?: string;
  user?: User;
  availability?: string;
  province_desc?: string;
  reg?: string;
  
  farmgame?: Array<FarmGame>;
  accomodation?: Array<Accomodation>;
  rating?: number;
  ratingcount?: number;
  isfavorite?: number;
  advert?: Advert;
}
