import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AppDataProvider } from '../../app.data.provider';
import { Lookup } from '../../../models/lookup.model';

@Component({
  selector: 'app-species',
  templateUrl: './species.page.html',
  styleUrls: ['./species.page.scss'],
})
export class SpeciesPage implements OnInit {
  animals: Array<Lookup>;

  constructor(
    private modalController: ModalController,
    private appDataProvider: AppDataProvider,
    private params: NavParams,
  ) {}

  ngOnInit() {
    let preSelectedAnimals = this.params.get('selectedAnimals');
    if (preSelectedAnimals.length != 0) this.animals = preSelectedAnimals;
    else {
      this.animals = this.appDataProvider.lookupData.filter(
        item => item.lookuptype == 'animalid',
      );
    }
  }

  async close() {
    await this.modalController.dismiss(this.animals);
  }
}
