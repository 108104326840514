import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Experience } from 'src/models/experience.model';
import { Lookup } from 'src/models/lookup.model';
import { AppDataProvider } from '../../app.data.provider';

@Component({
  selector: 'app-filter-photos',
  templateUrl: './filter-photos.page.html',
  styleUrls: ['./filter-photos.page.scss'],
})
export class FilterPhotosPage implements OnInit {
  // These will always be there, the model type ie. Experience will change depending on the listing.
  data: Array<Experience> = null;
  originalData: Array<Experience> = null;
  cities: Array<string>;
  provinces: Array<string>;
  selectedProvince: string;
  selectedCity: string;
  // Till here, reason is we will always have a province and city and the datasets to work with

  // Filters with dropdowns will have a list and a selected value. Others will only have a selectd value.
  animals: Array<Lookup>;
  selectedAnimal?: Lookup;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
    private appDataProvider: AppDataProvider,
  ) {
    this.data = new Array<Experience>();
    this.provinces = new Array<string>();
    this.cities = new Array<string>();
    this.selectedProvince = undefined;
    this.selectedCity = undefined;
    this.selectedAnimal = undefined;
  }

  ngOnInit() {
    if (
      this.appDataProvider.user === null ||
      this.appDataProvider.user === undefined ||
      this.appDataProvider.lookupData === null ||
      this.appDataProvider.lookupData === undefined ||
      this.appDataProvider.lookupData.length === 0
    ) {
      setTimeout(() => {
        this.ngOnInit();
      }, 1000);
      return;
    }

    this.data = this.params.get('data');
    this.originalData = this.params.get('originalData');

    this.animals = this.appDataProvider.lookupData.filter(
      item => item.lookuptype == 'animalid',
    );

    this.originalData.map(record => {
      if (this.cities.indexOf(record.user.city) === -1)
        this.cities.push(record.user.city);

      if (this.provinces.indexOf(record.user.province_desc) === -1)
        this.provinces.push(record.user.province_desc);
    });

    this.cities.sort();
    this.provinces.sort();

    // ES: When the screen opens, pass the previous selections here if it was set....
    if (this.params.get('filtedApplied')) {
      this.selectedProvince = this.params.get('selectedProvince');
      this.selectedCity = this.params.get('selectedCity');
      this.selectedProvince = this.params.get('selectedProvince');
      this.selectedAnimal = this.params.get('selectedAnimal');
    }
  }

  async back() {
    await this.modalController.dismiss({
      selectedProvince: undefined,
      selectedCity: undefined,
      selectedAnimal: undefined,
      filtedApplied: false,
      data: this.originalData,
    });
  }

  async reset() {
    await this.modalController.dismiss({
      selectedProvince: undefined,
      selectedCity: undefined,
      selectedAnimal: undefined,
      filtedApplied: false,
      data: this.originalData,
    });
  }

  async apply() {
    let newData = this.originalData;

    if (this.selectedCity !== undefined) {
      newData = newData.filter(item => {
        return item.user.city === this.selectedCity;
      });
    }

    if (this.selectedProvince !== undefined) {
      newData = newData.filter(item => {
        return item.user.province_desc === this.selectedProvince;
      });
    }

    if (this.selectedAnimal !== undefined) {
      newData = newData.filter(item => {
        return item.animalid == this.selectedAnimal;
      });
    }

    await this.modalController.dismiss({
      selectedProvince: this.selectedProvince,
      selectedCity: this.selectedCity,
      selectedAnimal: this.selectedAnimal,
      filtedApplied: true,
      data: newData,
    });
  }
}
