import { createInjectorType } from '@angular/compiler/src/render3/r3_injector_compiler';
import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { User } from 'src/models/user.model';

@Component({
  selector: 'app-sort-search-results',
  templateUrl: './sort-search-results.page.html',
  styleUrls: ['./sort-search-results.page.scss'],
})
export class SortSearchResultsPage implements OnInit {
  data: Array<User> = null;
  originalData: Array<User> = null;
  sortField: string;
  sortDirection: string;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {
    this.data = new Array<User>();
    this.sortDirection = 'ascending';
    this.sortField = 'availability';
  }

  ngOnInit() {
    this.data = this.params.get('data');
    this.originalData = this.params.get('originalData');
  }

  async back() {
    await this.modalController.dismiss({});
  }

  async reset() {
    await this.modalController.dismiss(this.originalData);
  }

  async apply() {
    let newData = this.data.sort((a, b) => {
      if (this.sortField === 'availability') {
        let a_availability = a.availabilityjson.split(',').length;
        let b_availability = b.availabilityjson.split(',').length;
        let sortResult = 0;
        if (a_availability === b_availability) sortResult = 0;
        else if (a_availability >= b_availability) sortResult = -1;
        else sortResult = 1;
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'price') {
        let sortResult = 0;
        if (a.hunt_day_fee === b.hunt_day_fee) sortResult = 0;
        else if (a.hunt_day_fee >= b.hunt_day_fee) sortResult = -1;
        else sortResult = 1;
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'rating') {
        let sortResult = 0;
        if (a.rating === b.rating) sortResult = 0;
        else if (a.rating >= b.rating) sortResult = -1;
        else sortResult = 1;
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'ratingcount') {
        let sortResult = 0;
        if (a.ratingcount === b.ratingcount) sortResult = 0;
        else if (a.ratingcount >= b.ratingcount) sortResult = -1;
        else sortResult = 1;
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'province') {
        let sortResult = a.province_desc.localeCompare(b.province_desc);
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'city') {
        let sortResult = a.city.localeCompare(b.city);
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      }

      return 0;
    });

    await this.modalController.dismiss(newData);
  }
}
