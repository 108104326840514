import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Experience } from 'src/models/experience.model';

@Component({
  selector: 'app-sort-photos',
  templateUrl: './sort-photos.page.html',
  styleUrls: ['./sort-photos.page.scss'],
})
export class SortPhotosPage implements OnInit {
  data: Array<Experience> = null;
  originalData: Array<Experience> = null;
  sortField: string;
  sortDirection: string;

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {
    this.data = new Array<Experience>();
    this.sortDirection = 'ascending';
    this.sortField = 'availability';
  }

  ngOnInit() {
    this.data = this.params.get('data');
    this.originalData = this.params.get('originalData');
  }

  async back() {
    await this.modalController.dismiss({});
  }

  async reset() {
    await this.modalController.dismiss(this.originalData);
  }

  async apply() {
    let newData = this.data.sort((a, b) => {
      if (this.sortField === 'animal') {
        let sortResult = 0;
        // Note - this is not technically correct as we need to compare the animal names not the lookup table ID. but you get the idea. Will gix this later.
        if (a.animalid == b.animalid) sortResult = 0;
        else if (a.animalid >= b.animalid) sortResult = -1;
        else sortResult = 1;
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'province') {
        let sortResult = a.user.province_desc.localeCompare(
          b.user.province_desc,
        );
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      } else if (this.sortField === 'city') {
        let sortResult = a.user.city.localeCompare(b.user.city);
        if (this.sortDirection === 'descending') sortResult = sortResult * -1;
        return sortResult;
      }

      return 0;
    });

    await this.modalController.dismiss(newData);
  }
}
