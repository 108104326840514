import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { CalendarComponentOptions } from 'ion2-calendar';

@Component({
  selector: 'app-date-picker-modal',
  templateUrl: './date-picker-modal.page.html',
  styleUrls: ['./date-picker-modal.page.scss'],
})
export class DatePickerModalPage implements OnInit {
  selectedDate: string;
  type: 'string';
  optionsRange: CalendarComponentOptions = {
    pickMode: 'single',
    color: 'secondary',
  };

  constructor(
    public modalController: ModalController,
    private params: NavParams,
  ) {}

  ngOnInit() {
    this.selectedDate = '';
  }

  async onChange($event) {
    this.selectedDate = $event.format('YYYY-MM-DD');
    await this.modalController.dismiss({ date: this.selectedDate });
  }

  async back() {
    await this.modalController.dismiss({ date: '' });
  }
}
